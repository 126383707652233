<template>
  <div>
     <div class="flex justify-center px-5 py-5">
       <img src="/error/404.svg" alt="">
     </div>
     <div class="flex justify-center px-5 py-5">
       <span class="text-gray-500 font-medium tracking-wider text-2xl">Lo sentimos, la pagina no fue encontrada...</span>
     </div>
     <div class="flex justify-center px-5 py-5">
       <div>
         <router-link to="/">
         <button class="btn-full-green px-10">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
          <span class="text-white">Ir al inicio</span>
         </button></router-link>
       </div>
     </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>